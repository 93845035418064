import * as React from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/Layout/Layout'
import Title from '../components/Title/Title'
import * as ContactStyles from './contact.module.css'
import Underline from '../components/Underline/Underline'
import Form from '../components/Form/Form'
import Map from '../components/Map/Map'

function Contact() {
    return (
        <Layout>
            <Helmet>
                <title>Contact us | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={ContactStyles.titleSection}>
                <Title>
                    <h1>Contact us</h1>
                </Title>
            </section>
            <section className={ContactStyles.contactContainer}>
                <div>
                    <Underline>
                        <h2>Get in Touch</h2>
                    </Underline>
                    <p>If you'd like to discuss an appointment or if you have any questions you can contact us using the details below or by filling in the contact form.</p>
                    <h4>Contact details</h4>
                    <a className={ContactStyles.contact} href="tel:+441604328260"><FontAwesomeIcon style={{marginRight: 10}} icon={faPhone} />01604 328 260</a>
                    <a className={ContactStyles.contact} href="mailto: info@brixworthosteopathy.com"><FontAwesomeIcon style={{marginRight: 10}} icon={faEnvelope} />info@brixworthosteopathy.com</a>
                    <h4>Location</h4>
                    <p>Our team operate from a relaxing and quiet clinic boasting convenient parking and disabled access.</p>
                    <p className={ContactStyles.address}>
                        Brixworth Osteopathic Clinic<br />
                        Lamport Manor Farm<br />
                        Coach House 2<br />
                        Old Road<br />
                        Lamport<br />
                        Northants<br />
                        NN6 9HF
                    </p>
                </div>
                <div>
                    <Form />
                </div>
            </section>
            <section className={ContactStyles.map}>
                <Map height="450px" />
            </section>
        </Layout>
    )
}

export default Contact